<template>
  <pharmaceutic-profile-component />
</template>

<script>
import PharmaceuticProfileComponent from "@/components/pharmaceutics/PharmaceuticProfileComponent";

export default {
  name: "PharmaceuticProfile",
  title: "Perfil de Farmacia | Baja California Health Tourism",
  components: { PharmaceuticProfileComponent },
};
</script>

<style scoped></style>
